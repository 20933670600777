<template>
	<div>
		<common-table
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				:customHtml="true"
				ref="ct"
				:btnActionEvent="btnActionEvent"
				pageTitle="综合预约"
				@otherTableOperate="otherTableOperate"
		>
			<template v-slot:customHtml>
				<advanced-search
						:fixedConfig="leftConfig"
						@search="search"
						:hasExportFile="true"
						@exportFile="exportFile"
				/>
			</template>
			<template v-slot:status="{ item }">
				<!-- 格式化支付状态 -->
				<formatter-field :status="item.status" field="payStatus"/>
			</template>
		</common-table>
		<template v-if="dialog">
			<exhibition-data-box
					:contentHeight="500"
					:width="936"
					:dialog.sync="dialog"
					usageMode="tabs"
					:items="items">
				<template v-slot:footer>
					<div style="float: right" class="mt-4">
						<v-btn
								:disabled="cancelBtnStatus"
								:loading="cancelBtnLoading"
								color="redness white--text"
								depressed @click="cancel">
							取消预约
						</v-btn>
					</div>
					<div style="clear: both;"></div>
				</template>
			</exhibition-data-box>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/orderManage/synthesize-appointment.js';
import formatterField from '../formatter.vue';
import advancedSearch from "@/components/advancedSearch/advancedSearch.vue";
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';
import detailsLog from '../common-order-js/details-log.js';

export default {
	// 预约记录
	components: {advancedSearch, formatterField, exhibitionDataBox},
	mixins: [commonCurd, detailsLog],
	data() {
		return {
			api,
			items: [],
			dialog: false,
			listDeploy: {},
			btnActionEvent: [
				{
					event: 'getDetails',
					icon: 'chakan-fill',
					color: 'primary',
					tooltip: '预约详情'
				}
			],
			headers: [
				{text: '预约单号', value: 'bookingid'},
				{text: '预约项目', value: 'groupid'},
				{text: '预约人姓名', value: 'bookername'},
				{text: '预约人电话', value: 'telephone'},
				{text: '预约时段', value: 'period'},
				{text: '订单金额', value: 'amount'},
				{text: '状态', value: 'status'},
				{text: '创建时间', value: 'createtime'},
				{text: '操作', value: 'actions', width: '80px'}
			],
			leftConfig: [
				{type: 'input', label: '输入预约单号', property: 'bookingid'},
				{type: 'input', label: '输入预约人电话', property: 'name'},
				{type: 'select', label: '预约项目', property: 'groupid', items: []},
				{type: 'select', label: '预约时段', property: 'period', items: []},
				{type: 'select', label: '状态', property: 'status', items: []}
			],
			cancelBtnStatus: false,
			cancelBtnLoading: false,
			orderItems: {
				deploy: {
					row: [
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'bookingid',
							connector: {
								props: {
									label: "预约单号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'groupid',
							connector: {
								props: {
									label: "预约项目",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'statusdesc',
							connector: {
								props: {
									label: "订单状态",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'sitecode',
							connector: {
								props: {
									label: "预约场所",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'usedate',
							connector: {
								props: {
									label: "预约日期",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'period',
							connector: {
								props: {
									label: "预约时段",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'bookername',
							connector: {
								props: {
									label: "预约人",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'telephone',
							connector: {
								props: {
									label: "预约人手机号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'roomno',
							connector: {
								props: {
									label: "预约房号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'amount',
							connector: {
								props: {
									label: "预约总金额",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'payamount',
							connector: {
								props: {
									label: "实际支付金额",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'payment',
							connector: {
								props: {
									label: "支付方式",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'paytime',
							connector: {
								props: {
									label: "支付时间",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'payno',
							connector: {
								props: {
									label: "支付订单号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'payid',
							connector: {
								props: {
									label: "支付流水号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'createtime',
							connector: {
								props: {
									label: "创建时间",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'refund',
							connector: {
								props: {
									label: "退款金额",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'refundtime',
							connector: {
								props: {
									label: "退款时间",
									readonly: true
								}
							}
						}
					]
				}
			},
			temp: {}
		}
	},
	methods: {
		async otherTableOperate(event, item) {
			this.temp = {}
			this.axios.post(api.load, {sqlid: item.sqlid}).then(res=> {
				this.temp = res.data
				this.orderItems.deploy.row.forEach((e) => {
					delete e.default
					if (res.data[e.property]) {
						e.default = res.data[e.property];
					}
				});
				this.items = [{title: '预约', mainbody: {module: 'forms', binds: this.orderItems}}]
				this.axios.post(api.orderLog, {type: 'ACTGROUP', regno: item.bookingid}).then(r => {
					if (r.code === this.staticVal.Code.Success) {
						this.tableProps.listData = r.data;
					}
				});
				this.items = this.items.concat(
						[{title: '日志', mainbody: {module: 'tables', binds: this.tableProps}}]
				);
				this.dialog = true
			})
		},
		cancel() {
			if (this.temp.bookingid) {
				this.cancelBtnLoading = true
				this.axios.post(api.cancel, {bookingid: this.temp.bookingid}).then(res=> {
					this.cancelBtnLoading = false
					this.snackbar.success(res.msg);
					this.otherTableOperate('', this.temp)
				})
			}
		}
	},
	mounted() {
		this.axios.post(this.select_data, {keyname: 'actgroup,actperiod,actstatus'}).then((res) => {
			if (res.code === this.staticVal.Code.Success) {
				this.leftConfig[2].items = res.data[0].values;
				this.leftConfig[3].items = res.data[1].values;
				this.leftConfig[4].items = res.data[2].values;
			}
		});
	}
}
</script>

<style scoped lang="scss">

</style>
